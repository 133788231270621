@import "var.conf";









/*---------------------------------
  Common CSS 2016.11
  CSS Tonooka Document 2016.11
---------------------------------*/


/* 基本設定
-------------------------------------*/


/* webフォント
* 指示がある場合はこの位置で一括インポート
*/

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);










/* 基本設定 */

* {
  //padding, borderによる領域崩壊回避
  box-sizing: border-box;
}



body {
  position: relative;
  background: #fefbf3;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  font-weight: 500;
  color: $color_font;
}























































































































/* Footer Copyright h2 */

#Copyright h2 {
  // 以下は固定
  display: none;
}







a[href^="tel"]:hover {
  cursor: text;
  opacity: 1;
}

















































































































/* clearfix */

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

























































































































/* テスト時のクラス */

.testBorder {
  border: 1px solid #ff0000;
  padding: 1px;
  background-color: #77FFB7;
  * {
    background-color: transparent;
  }
}

div#TestDesign {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.3;
  background: url(../img/design/design.png) no-repeat center top;
  background-size: 1500px 2300px;
}
















/*
  共通
  -------------------------------------*/

.orange {
  color: #e9633a !important;
}

@media print,
screen and (min-width: 768px) {
  /* p,li 共通 */
  #Main p,
  #Main ul li {
    color: #000;
    font-size: 20px;
    text-align: justify;
  }

  #Main p {
    line-height: 34px;
    &+p {
      margin-top: 20px;
    }
  }

  /* リンク設定 */
  a {
    &:active {
      color: #010101;
      text-decoration: none;
    }
    &:link {
      color: #010101;
      text-decoration: none; // transition: all 0.5s ease;
    }
    &:visited {
      color: #010101;
      text-decoration: none;
    }
    &:hover {
      color: #010101;
      opacity: 0.85;
    }
  }
  /* SPのみで表示 */
  .dispsp {
    display: none;
  }
  /* コンテンツ基本幅 */
  .innerBasic {
    width: $pc_basicInner; //var.confで定義
    margin-right: auto; //センター寄せ
    margin-left: auto;
  }

  /***
    * 基本レイアウト構造
    */
  #Header {
    /* Headerを上部に固定する場合 */
    //position: fixed;
    //z-index: 500;
    //top: 0;
    width: 100%;
    background: #fefbf3;
    min-width: 1000px;
    div.innerBasic {
      position: relative;
      #ContBoxHeader {
        display: table-cell;
        width: 100%;
        height: 107px;
        vertical-align: middle;
        h1 {
          background: url("/common/img/logo.png") no-repeat scroll left center;
          background-size: 386px auto;
          width: 386px;
          height: 62px;
          text-indent: -9999px;
          a {
            display: block;
            width: 100%;
            height: 100%;
            span {
              text-indent: -9999px;
            } // span {
            //   font-family: "メイリオ", sans-serif;
            //   color: #714721;
            //   font-size: 36px;
            //   font-weight: 400;
            //   letter-spacing: 3px;
            //   &.headeTop {
            //     display: block;
            //     font-family: "Yu Gothic";
            //     font-size: 16px;
            //     font-weight: 500;
            //     text-align: left;
            //     margin-top: 5px;
            //     margin-bottom: 10px;
            //     letter-spacing: 1px;
            //   }
            // }
          }
        }
        a:link,
        a:visited {
          color: #010101;
        }
        a:hover {
          color: #010101;
        }
        #HeaderLogo {
          display: table-cell;
          width: 252px;
          height: 120px;
          vertical-align: middle;
        }
        #HeaderMenu {
          display: table;
          position: absolute;
          top: 0;
          right: 0px;
          height: 107px;
          margin-left: auto;
          ul {
            position: relative;
            li {
              display: table-cell;
              vertical-align: middle;
              white-space: nowrap;
              &.tel {
                position: absolute;
                top: 30px;
                right: 140px;
                width: 236px;
                span {
                  display: block;
                  text-align: right;
                  &:nth-of-type(1) {
                    color: #444444;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    margin-bottom: 6px;
                  }
                  &:nth-of-type(2) {
                    font-family: 'Open Sans', sans-serif;
                    color: #000000;
                    font-size: 32px;
                    font-weight: 600;
                    &:before {
                      content: "";
                      background: url("/common/img/tel_icon.png") no-repeat scroll left center;
                      background-size: 30px auto;
                      display: inline-block;
                      width: 30px;
                      height: 20px;
                      position: relative;
                      right: 7px;
                    }
                  }
                }
              }
              &.advice {
                position: absolute;
                top: 30px;
                right: 0;
                border-radius: 5px;
                border: 2px solid #e9633a;
                background-color: #fff;
                width: 123px;
                height: 48px;
                text-align: center;
                padding-top: 4px;
                a {
                  color: #e9633a;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 18px;
                }
              }
            }
          }
        }
        #MenuBtn.dispsp {
          display: none;
        }
      }
    }
  }

  #GlobalNav {
    z-index: 100;
    width: 100%;
    height: 59px;
    background: #fefbf3;
    min-width: 1000px;
    ul {
      /*以下1行削除しないように li display inline-blockカラム落ち対策*/
      font-size: 0;
      li {
        display: inline-block;
        text-align: center;
        height: 59px;
        a {
          margin: 0 auto;
        }
        &:nth-of-type(1) {
          width: 143px;
          a {
            width: 70px;
          }
        }
        &:nth-of-type(2) {
          width: 185px;
          a {
            width: 193px;
          }
        }
        &:nth-of-type(3) {
          width: 255px;
          a {
            width: 186px;
          }
        }
        &:nth-of-type(4) {
          width: 100px;
          a {
            width: 95px;
          }
        }
        &:nth-of-type(5) {
          width: 148px;
          a {
            width: 93px;
          }
        }
        &:nth-of-type(6) {
          width: 140px;
          a {
            width: 131px;
          }
        }
        a {
          display: block;
          height: 43px;
          color: #000;
          font-size: 18px;
          line-height: 46px;
        }
      }
    }
  }

  #Page #GlobalNav ul li:nth-of-type(1) a,
  #PageContribution #GlobalNav ul li:nth-of-type(2) a,
  #PageContributionlist #GlobalNav ul li:nth-of-type(3) a,
  #PageTopics #GlobalNav ul li:nth-of-type(4) a,
  #PageCompany #GlobalNav ul li:nth-of-type(5) a,
  #PageContact #GlobalNav ul li:nth-of-type(6) a {
    border-bottom: 3px solid #86c441;
  }

  #MainImg {
    width: 100%;
    background: $color_basic1 url(../img/lowerImage.jpg) no-repeat center center;
    background-size: cover;
    min-width: 1000px;
    div#MainImgInner {
      margin: 0 auto;
      width: 100%;
      height: 240px;
      h2 {
        color: #fff;
        font-size: 60px;
        font-weight: 700;
        line-height: 240px;
        text-align: center;
        text-shadow: 0px 0 11px rgba(0, 0, 0, 0.2), 0px 0 11px rgba(0, 0, 0, 0.2);
      }
    }
  }
  #Page.pageIndex #MainImg div#MainImgInner {
    height: 650px;
  }
  /*TOPページ用定義*/
  #Page.pageIndex div#MainImg {
    background: $color_basic1 url(../img/mainimg_index.jpg) no-repeat center bottom;
    background-size: cover;
    h2 {
      position: relative;
      width: 1000px;
      background: url("/common/img/contents/mainimg_icon01.png") no-repeat scroll right bottom;
      background-size: 219px auto;
      padding-top: 400px;
      font-size: 70px;
      font-weight: 700;
      line-height: 95px;
      color: #fff;
      text-align: left;
      text-shadow: 0px 0 15px rgba(58, 93, 11, 0.3), 0px 0 15px rgba(58, 93, 11, 0.3);
      .mainimgSpan,
      .mainimgSpan2 {
        display: inline-block;
      }
      .mainimgSpan2 {
        position: absolute;
        bottom: 66px;
        right: 18px;
        span {
          display: block;
          color: #f64c18;
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          text-align: center;
          text-shadow: none;
          &:nth-of-type(2) {
            margin-top: 4px;
            font-size: 24px;
            line-height: 30px;
            span {
              font-size: 24px;
              line-height: 30px;
              letter-spacing: -1.5px;
            }
          }
        }
      }
    }
  }
  #Container {
    width: 100%;
    background: #fefbf3;
    min-width: 1000px;
    &:after {
      content: "";
      display: block;
      visibility: hidden;
      clear: both;
      height: 0;
    }
  }
  /*PC #TopicPath  パンくずリスト*/
  #TopicPath {
    /*以下1行削除しないように li display inline-blockカラム落ち対策*/
    font-size: 0;
    display: block;
    margin: 0 auto 10px;
    width: $pc_basicInner;
    padding: 10px 8px 5px 0;
    text-align: left;
    li {
      display: inline-block;
      margin-right: 5px;
      color: #9e9e9e;
      font-size: 14px;
      line-height: 25px;
      a {
        display: inline-block;
        &:active,
        &:link,
        &:visited {
          color: #000;
        }
        &:hover {
          color: #000;
          text-decoration: underline;
        }
      } // 2つめ以降のパンくずリストの前置記号
      &+li {
        position: relative;
        padding-left: 16px;
        &:before {
          content: ">";
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 7px;
          height: 25px;
        }
      }
    }
  }
  /* #Main */
  #Main {
    width: 700px;
    float: left;
    min-height: 100px;
    h3 {
      color: #714721;
      font-size: 50px;
      font-weight: 700;
      text-align: center;
    }
  }
  /* .sub */
  aside.sub {
    width: 240px;
    float: right;
    margin-top: 83px;
    margin-bottom: 210px;
    h3 {
      height: 40px;
      color: #714721;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      text-align: left;
      padding-left: 0;
      &:nth-of-type(2) {
        margin-top: 40px;
      }
    }
    li.parent {
      display: block !important;
    }
    .current,
    .current-cat {
      a {
        color: #9e9e9e !important;
        &:hover {
          opacity: 1;
          text-decoration: none !important;
          cursor: text;
        }
      }
    }
    ul {
      width: 100%;
      margin: 0 auto;
      border: 1px solid #f0e9cf;
      border-radius: 15px;
      background-color: #fff8e0;
      padding: 20px 30px;
      li {
        display: block;
        width: 100%;
        color: #010101;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        background: url("/common/img/contents/arrow01.png") no-repeat scroll left center;
        background-size: 8px auto;
        padding-left: 13px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          line-height: 45px;
          width: 100%;
          height: 45px;
          padding-left: 10px;
          color: #333;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    &>ul.monthly {
      width: 100%;
      li {
        display: block;
        height: auto;
      }
      &>li {
        background: url(../img/contents/sp_icon_close.png) no-repeat top 14px left;
        background-size: 10px 10px;
        cursor: pointer;
        &.minus {
          background: url(../img/contents/sp_icon_open.png) no-repeat top 14px left;
          background-size: 10px 10px;
        }
        &>span {
          display: block;
          width: 100%;
          line-height: 40px;
          padding-left: 10px;
        }
        ul {
          display: none;
          border: none;
          padding: 0 12px;
          li {
            background: none;
            padding-left: 0;
            a {
              font-size: 18px;
              color: #80ab50;
              line-height: 37px;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  /* .pageMove */
  nav.pageMove {
    margin: 40px 0;
    ul {
      width: 475px;
      margin: 0 auto;
      position: relative;
      text-align: center;
      font-size: 0;
      li {
        width: 35px;
        height: 36px;
        display: inline-block;
        line-height: 36px;
        text-align: center !important;
        font-size: 18px !important;
        color: #c5c5c5 !important;
        &.current {
          display: inline-block;
          &+li {
            margin-left: 2px;
          }
        }
        a {
          text-align: center;
          color: #80ab50;
          &:hover {
            text-decoration: underline;
          }
        }
        &.prev,
        &.next {
          width: 100px;
          height: 36px;
          line-height: 36px;
          font-size: 16px !important;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #fff;
            background-color: #80ab50;
            border-radius: 20px;
            &:hover {
              text-decoration: none;
            }
          }
        }
        &.prev {
          position: absolute;
          top: 0;
          left: 0;
          a {
            padding-right: 15px;
            &:before {
              content: "";
              display: inline-block;
              width: 7px;
              height: 14px;
              margin-right: 13px;
              background: url(../img/icon_prev.png) no-repeat center left;
              background-size: 7px auto;
            }
          }
        }
        &.next {
          position: absolute;
          top: 0;
          right: 0;
          a {
            padding-left: 15px;
            &:after {
              content: "";
              display: inline-block;
              width: 7px;
              height: 14px;
              margin-left: 13px;
              background: url(../img/icon_next.png) no-repeat center left;
              background-size: 7px auto;
            }
          }
        }
      }
    }
    &.entryPageMove {
      margin-top: 30px;
      ul {
        width: 240px;
        &:last-of-type {
          display: block;
        }
        li {
          &.prev {
            left: -90px;
          }
          &.next {
            right: -88px;
          }
          &:not(.btn) {
            width: auto;
            a {
              font-size: 16px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }


  /*PC #FooterContact  レイアウト*/
  #FooterContact {
    height: 251px;
    background: #fef0d9;
    min-width: 1000px;
    .footerCopy {
      width: 100%;
      margin-bottom: 23px;
      padding-top: 61px;
      font-size: 26px;
      text-align: center;
      color: #714721;
      font-weight: 700;
      padding-left: 14px;
      letter-spacing: 0.05em;
    }
    .contactSet {
      overflow: hidden;
      width: 785px;
      height: 84px;
      margin: 0 auto;
      .tel {
        float: left;
        width: 401px;
        margin-right: 17px;
        color: #fff;
        text-align: left;
        span {
          &:nth-of-type(1) {
            height: 57px;
            display: inline-block;
            padding-left: 52px;
            background: url("/common/img/tel_icon.png") no-repeat scroll left center;
            background-size: 42px auto;
            font-family: 'Open Sans', sans-serif;
            text-align: right;
            a {
              color: #000;
              font-size: 50px;
              font-weight: 600;
            }
          }
          &:nth-of-type(2) {
            display: block;
            text-align: center;
            letter-spacing: 1px;
            color: #e9633a;
            font-size: 15px;
            font-weight: 700;
            border-radius: 5px;
            border: 1px solid #e9633a;
            background-color: #fff;
            width: 368px;
            height: 24px;
            line-height: 24px;
          }
        }
      }
      .mail {
        float: left;
        width: 367px;
        padding-top: 6px;
        a {
          display: block;
          width: 367px;
          height: 70px;
          padding-left: 123px;
          border-radius: 50px;
          background: #e9633a url("../img/mail_icon.png") no-repeat scroll 73px center;
          background-size: 35px auto;
          color: #fff;
          font-size: 26px;
          letter-spacing: 2px;
          text-decoration: none;
          line-height: 70px;
        }
      }
    }
  }
  /*PC #Footer  フッタ内  レイアウト*/
  #Footer {
    min-width: 1000px;
    padding-top: 60px;
    padding-bottom: 56px;
    background-color: #483421;
    #FooterInfo {
      float: left;
      width: 360px;
      dl {
        dt {
          height: 32px;
          color: #e9d8c6;
          font-size: 22px;
          letter-spacing: 1px;
          a {
            color: #e9d8c6;
            margin-left: 12px;
          }
        }
        dd {
          color: #e9d7c5;
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0.7px;
          span {
            font-size: 12px;
            vertical-align: bottom;
            a {
              color: #e9d7c5;
              vertical-align: bottom;
            }
          }
        }
        dt,
        dd {
          a {
            font-size: 12px;
            text-decoration: underline;
            vertical-align: middle;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    #FooterNav {
      float: right;
      width: 500px;
      color: #fff;
      text-align: right;
      ul {
        li {
          display: inline-block;
          margin-bottom: 18px;
          color: #fff;
          font-size: 16px;
          border-right: 1px solid #644e3a;
          text-align: center;
          &:nth-of-type(1) {
            width: 85px;
          }
          &:nth-of-type(2) {
            width: 195px;
          }
          &:nth-of-type(3) {
            width: 169px;
          }
          &:nth-of-type(4) {
            width: 110px;
            letter-spacing: -1px;
          }
          &:nth-of-type(5) {
            width: 106px;
          }
          &:nth-of-type(6) {
            width: 119px;
            margin-right: 3px;
          }
          &:nth-of-type(3),
          &:nth-of-type(6) {
            text-align: right;
            border: none;
          }
          a {
            color: #fff;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    #Copyright {
      margin-top: 27px;
      font-family: "Open Sans", sans-serif;
      font-size: 10px;
      letter-spacing: .8px;
      color: #6f5f4f;
    }
  }
  /*PC  #PageTop  */
  #PageTop {
    position: fixed;
    z-index: 100;
    bottom: 82px;
    right: 20px;
    text-indent: -9999px;
    a:link,
    a:visited {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: url("../img/pageTop.png");
      background-size: 60px auto;
    }
    a:hover {
      opacity: .8;
    }
  }
}

@media print,
screen and (max-width: 767px) {

  #Main p,
  #Main ul li {
    color: #010101;
    font-size: 18px;
    line-height: 29px; // text-align: justify;
    &+p {
      margin-top: 11px;
    }
  }

  /* リンク設定 */
  a {
    &:link,
    &:visited {
      color: #010101;
    }
    &:active {
      color: #010101;
      text-decoration: none;
    }
    &:link {
      color: #010101;
      text-decoration: none; // transition: all 0.5s ease;
    }
  }
  /* 基本設定 */
  .disppc {
    display: none; //デフォルトはPC表示なのでSP部分は非表示化
  }

  .innerBasic {
    width: 100%;
  }
  /*Header*/
  #Header {
    position: relative;
    background: #fefbf3;
    height: 60px;
    #ContBoxHeader {
      display: table-cell;
      h1 {
        background: url("/common/img/logo.png") no-repeat scroll 12px center;
        background-size: 240px auto;
        width: 253px;
        height: 60px;
        padding-left: 11px;
        text-indent: -9999px;
        a {
          display: block;
          width: 100%;
          height: 100%; // span {
          //   font-family: "メイリオ", sans-serif;
          //   color: #714721;
          //   font-size: 22px;
          //   font-weight: 400;
          //   letter-spacing: 3px;
          //    &.headeTop {
          //      display: block;
          //      font-family: "Yu Gothic";
          //      font-size: 10px;
          //      font-weight: 500;
          //      text-align: left;
          //      margin-top: 13px;
          //      margin-bottom: 5px;
          //      letter-spacing: 1px;
          //    }
          // }
        }
      }
      #HeaderLogo {
        display: table-cell;
        width: 252px;
        height: 120px;
        vertical-align: middle;
      }
    }
    #MenuBtn {
      position: absolute;
      z-index: 600;
      top: 5px;
      right: 16px;
      width: 50px;
      height: 50px;
      padding-top: 15px;
      background: url(../img/header_menu.png) no-repeat center center;
      background-size: 50px auto;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      line-height: 50px;
    }
  }
  /* MenuSp GlobalNavSp */
  #MenuSp {
    display: none;
    width: 100%;
    position: absolute;
    top: 60px;
    z-index: 200;
    #HeaderMenu {
      //padding: 10px;
      ul {
        //border: 1px solid #000;
        li {
          display: block;
          text-align: center;
          background: $color_basic1; //line-height: 20px;
          a {}
        }
      }
    }
    nav#GlobalNavSp {
      background: #fff;
      ul {
        li {
          display: block;
          width: 100%;
          height: 59px;
          line-height: 59px;
          border-bottom: 1px solid #e7e7e7;
          font-size: 18px;
          background: url(../img/GlobalNavSp_menu.png) no-repeat right 18px center;
          background-size: 8px auto;
          &:nth-of-type(1) {
            border-top: 1px solid #e7e7e7;
          }
          a {
            display: table;
            width: 100%;
            height: 100%;
            padding-left: 10px;
            color: #000;
          }
        }
      }
      p.tel {
        a {
          font-weight: 600;
        }
      }
    }
  }

  /* .pageMove SP */
  nav.pageMove {
    margin: 24px 0;
    ul {
      width: 100%;
      margin: 0 auto;
      position: relative;
      text-align: center;
      font-size: 0;
      li {
        width: 30px;
        height: 30px;
        display: inline-block;
        line-height: 15px;
        text-align: center !important;
        font-size: 16px !important;
        color: #c5c5c5 !important;
        &.current {
          display: inline-block;
          &+li {
            margin-left: 2px;
          }
        }
        a {
          text-align: center;
          color: #80ab50;
        }
        &.prev,
        &.next {
          width: 100px;
          height: 36px;
          line-height: 36px !important;
          background-color: #80ab50;
          border-radius: 20px;
          font-size: 14px !important;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #fff;
          }
        }
        &.prev {
          position: absolute;
          top: 50px;
          left: 0;
          a {
            padding-right: 15px;
            &:before {
              content: "";
              display: inline-block;
              width: 7px;
              height: 14px;
              margin-right: 13px;
              background: url(../img/icon_prev.png) no-repeat center left;
              background-size: 7px auto;
              position: relative;
              top: 2px;
            }
          }
        }
        &.next {
          position: absolute;
          top: 50px;
          right: 0;
          a {
            padding-left: 15px;
            &:after {
              content: "";
              display: inline-block;
              width: 7px;
              height: 14px;
              margin-left: 13px;
              background: url(../img/icon_next.png) no-repeat center left;
              background-size: 7px auto;
              position: relative;
              top: 2px;
            }
          }
        }
      }
    }
    &.entryPageMove {
      margin-top: 16px;
      ul {
        width: 100%;
        li {
          &:not(.btn) {
            width: auto;
            a {
              font-size: 14px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }

  /*MenuspとFooterContact共通定義*/
  .contactArea {
    background: none !important;
    background-color: #fefbf3 !important;
    height: 230px !important;
    padding-top: 17px;
    .contactSet {
      width: 100%;
      .tel {
        color: #fff;
        width: 315px;
        margin: 0 auto 21px;
        span {
          display: block;
          &:nth-of-type(1) {
            height: 57px;
            background: url("/common/img/tel_icon.png") no-repeat scroll left 24px;
            background-size: 33px auto;
            font-family: 'Open Sans', sans-serif;
            text-align: right;
            margin-bottom: 1px;
            letter-spacing: 1px;
            width: 300px;
            margin-right: auto;
            margin-left: auto;
            a {
              color: #000;
              font-weight: 600;
              font-size: 39px;
              text-decoration: underline;
              padding-left: 0;
            }
          }
          &:nth-of-type(2) {
            text-align: center;
            letter-spacing: 1px;
            color: #e9633a;
            font-size: 13px;
            font-weight: 700;
            border-radius: 5px;
            border: 1px solid #e9633a;
            background-color: #fff;
            width: 100%;
            line-height: 24px;
          }
        }
      }
      .mail {
        width: 90%;
        margin: 0 auto;
        a {
          display: inline-block;
          background: #e9633a;
          width: 100%;
          height: 60px;
          border-radius: 50px;
          color: #fff !important;
          font-size: 24px;
          letter-spacing: 2px;
          text-decoration: none;
          line-height: 60px;
          text-align: center;
          span:before {
            display: inline-block;
            content: "";
            background: #e9633a url("../img/mail_icon.png") no-repeat scroll left center;
            background-size: 35px auto;
            height: 21px;
            width: 35px;
            position: relative;
            top: 2px;
            right: 14px;
          }
        }
      }
    }
  }

  /*FooterContact個別定義*/
  #FooterContact.contactArea {
    background-color: #fef0d9 !important;
    height: 350px !important;
    padding-top: 50px;
    .footerCopy {
      color: #714721;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      padding-bottom: 20px;
    }
    .contactSet .tel span:nth-of-type(1) {
      height: 48px;
      background: url(/common/img/tel_icon.png) no-repeat scroll left 13px !important;
      background-size: 33px auto !important;
    }
  }

  /*MenuBack*/
  #MenuBack {
    display: none;
    overflow: hidden;
    position: absolute;
    z-index: 100;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 1;
  }

  /*mainMainImg SP*/
  #MainImg {
    width: 100%;
    height: 120px;
    background: $color_basic1 url(../img/lowerImage.jpg) no-repeat center center;
    background-size: cover;
    h2 {
      width: 100%;
      font-size: 35px;
      font-weight: 700;
      line-height: 120px;
      text-align: center;
      color: #fff;
      text-shadow: 0 0 10px #93a380, 0 0 10px #93a380;
    }
  }
  /*TOPページ用定義*/
  #Page.pageIndex {
    #MainImg {
      position: relative;
      width: 100%;
      height: 325px;
      div#MainImgInner {
        background: $color_basic1 url(../img/mainimg_index.jpg) no-repeat center center;
        background-size: cover;
        width: 100%;
        height: 325px;
        h2 {
          position: absolute;
          bottom: 30px;
          color: #fff;
          font-size: 35px;
          font-weight: 700;
          text-align: left;
          line-height: 48px;
          padding-left: 15px;
          text-shadow: 0 0 10px #618b29, 0 0 10px #618b29;
        }
      }
    }
  }

  /*Container*/
  #Container {
    background: #fefbf3;
  }
  /*スマホパン屑*/
  #TopicPath {
    margin: 10px 0;
    width: 100%;
    height: 25px;
    font-size: 12px;
    padding: 0 15px;
    ol {
      li {
        display: inline;
        color: #9e9e9e;
        a {
          line-height: 25px;
          color: #000;
        }
        &+li {
          padding-left: 13px;
          position: relative;
          margin-left: 0;
        }
        &+li:before {
          content: ">";
          display: inline-block;
          position: absolute;
          top: 0;
          left: 2px;
          width: 7px;
          height: 10px;
          vertical-align: middle;
        }
        &:last-of-type:before {
          content: ">";
          display: inline-block;
          position: absolute;
          top: 1px;
          left: 1px;
          width: 7px;
          height: 10px;
          vertical-align: middle;
        }
      }
    }
  }
  /*Main*/
  #Main {
    h3 {
      color: #714721;
      font-size: 35px;
      font-weight: 700;
      text-align: center;
    }
    section#ContBox01 {
      padding: 0 15px;
      min-height: 100px;
    }
  }
  /* .sub */
  aside.sub {
    width: 90%;
    margin: 125px auto 50px;
    h3 {
      height: 36px;
      padding-left: 10px;
      color: #714721;
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
      text-align: left;
      padding-left: 0;
      &:nth-of-type(2) {
        margin-top: 24px;
      }
    }
    ul {
      width: 100%;
      margin: 0 auto;
      border: 1px solid #f0e9cf;
      border-radius: 20px;
      background-color: #fff8e0;
      padding: 15px 30px;
      li {
        display: block;
        width: 100%;
        color: #010101;
        font-size: 16px;
        font-weight: 500;
        line-height: 33px;
        background: url("/common/img/contents/arrow01.png") no-repeat scroll left center;
        background-size: 8px auto;
        padding-left: 13px;
        &:last-child {
          margin-bottom: 0;
        }
        &.current,
        &.current-cat {
          a:link,
          a:visited {
            color: #9e9e9e !important;
          }
        }
        a {
          line-height: 41px;
          width: 100%;
          padding-left: 10px;
          color: #333;
        }
      }
    }
    &>ul.monthly {
      width: 100%;
      li {
        display: block;
        height: auto;
      }
      &>li {
        background: url(../img/contents/sp_icon_close.png) no-repeat top 14px left;
        background-size: 10px 10px;
        cursor: pointer;
        &.minus {
          background: url(../img/contents/sp_icon_open.png) no-repeat top 14px left;
          background-size: 10px 10px;
        }
        &>span {
          display: block;
          width: 100%;
          line-height: 40px;
          padding-left: 10px;
        }
        ul {
          display: none;
          border: none;
          padding: 0 12px;
          li {
            background: none;
            padding-left: 0;
            a {
              font-size: 16px;
              color: #80ab50;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
  /*Footer*/
  #Footer {
    width: 100%;
    background: #483421;
    #FooterMenu {
      display: none;
    }
    #FooterInfo {
      width: 100%;
      padding: 50px 15px 30px;
      dl {
        dt {
          color: #e9d8c6;
          font-size: 20px;
          letter-spacing: 1px;
          margin-bottom: 20px;
          a {
            font-size: 10px;
            color: #e9d8c6;
            margin-left: 10px;
          }
        }
        dd {
          color: #e9d7c5;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.7px;
          span {
            font-size: 12px;
            a {
              font-size: 10px;
              color: #e9d7c5;
            }
          }
        }
        dt,
        dd {
          a {
            position: relative;
            top: -1px;
            text-decoration: underline;
            vertical-align: middle;
          }
        }
      }
    }
    #FooterNav nav {
      display: none;
    }
    #Copyright {
      padding: 13px 15px;
      small,
      address {
        font-size: 9px;
        color: #6f5f4f;
        line-height: 14px;
      }
    }
  }
  /*sp  #PageTop  */
  #PageTop {
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 5px;
    text-indent: -9999px;
    a:link,
    a:visited {
      display: block;
      width: 67px;
      height: 67px;
      border-radius: 50%;
      background: url("../img/pageTop.png") no-repeat center center;
      background-size: 80%;
    }
  }
}