@charset "UTF-8";
/*---------------------------------
  Common CSS 2016.11
  CSS Tonooka Document 2016.11
---------------------------------*/
/* 基本設定
-------------------------------------*/
/* webフォント
* 指示がある場合はこの位置で一括インポート
*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
/* 基本設定 */
* {
  box-sizing: border-box; }

body {
  position: relative;
  background: #fefbf3;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  font-weight: 500;
  color: #333333; }

/* Footer Copyright h2 */
#Copyright h2 {
  display: none; }

a[href^="tel"]:hover {
  cursor: text;
  opacity: 1; }

/* clearfix */
.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

/* テスト時のクラス */
.testBorder {
  border: 1px solid #ff0000;
  padding: 1px;
  background-color: #77FFB7; }
  .testBorder * {
    background-color: transparent; }

div#TestDesign {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.3;
  background: url(../img/design/design.png) no-repeat center top;
  background-size: 1500px 2300px; }

/*
  共通
  -------------------------------------*/
.orange {
  color: #e9633a !important; }

@media print, screen and (min-width: 768px) {
  /* p,li 共通 */
  #Main p,
  #Main ul li {
    color: #000;
    font-size: 20px;
    text-align: justify; }
  #Main p {
    line-height: 34px; }
    #Main p + p {
      margin-top: 20px; }
  /* リンク設定 */
  a:active {
    color: #010101;
    text-decoration: none; }
  a:link {
    color: #010101;
    text-decoration: none; }
  a:visited {
    color: #010101;
    text-decoration: none; }
  a:hover {
    color: #010101;
    opacity: 0.85; }
  /* SPのみで表示 */
  .dispsp {
    display: none; }
  /* コンテンツ基本幅 */
  .innerBasic {
    width: 1000px;
    margin-right: auto;
    margin-left: auto; }
  /***
    * 基本レイアウト構造
    */
  #Header {
    /* Headerを上部に固定する場合 */
    width: 100%;
    background: #fefbf3;
    min-width: 1000px; }
    #Header div.innerBasic {
      position: relative; }
      #Header div.innerBasic #ContBoxHeader {
        display: table-cell;
        width: 100%;
        height: 107px;
        vertical-align: middle; }
        #Header div.innerBasic #ContBoxHeader h1 {
          background: url("/common/img/logo.png") no-repeat scroll left center;
          background-size: 386px auto;
          width: 386px;
          height: 62px;
          text-indent: -9999px; }
          #Header div.innerBasic #ContBoxHeader h1 a {
            display: block;
            width: 100%;
            height: 100%; }
            #Header div.innerBasic #ContBoxHeader h1 a span {
              text-indent: -9999px; }
        #Header div.innerBasic #ContBoxHeader a:link,
        #Header div.innerBasic #ContBoxHeader a:visited {
          color: #010101; }
        #Header div.innerBasic #ContBoxHeader a:hover {
          color: #010101; }
        #Header div.innerBasic #ContBoxHeader #HeaderLogo {
          display: table-cell;
          width: 252px;
          height: 120px;
          vertical-align: middle; }
        #Header div.innerBasic #ContBoxHeader #HeaderMenu {
          display: table;
          position: absolute;
          top: 0;
          right: 0px;
          height: 107px;
          margin-left: auto; }
          #Header div.innerBasic #ContBoxHeader #HeaderMenu ul {
            position: relative; }
            #Header div.innerBasic #ContBoxHeader #HeaderMenu ul li {
              display: table-cell;
              vertical-align: middle;
              white-space: nowrap; }
              #Header div.innerBasic #ContBoxHeader #HeaderMenu ul li.tel {
                position: absolute;
                top: 30px;
                right: 140px;
                width: 236px; }
                #Header div.innerBasic #ContBoxHeader #HeaderMenu ul li.tel span {
                  display: block;
                  text-align: right; }
                  #Header div.innerBasic #ContBoxHeader #HeaderMenu ul li.tel span:nth-of-type(1) {
                    color: #444444;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    margin-bottom: 6px; }
                  #Header div.innerBasic #ContBoxHeader #HeaderMenu ul li.tel span:nth-of-type(2) {
                    font-family: 'Open Sans', sans-serif;
                    color: #000000;
                    font-size: 32px;
                    font-weight: 600; }
                    #Header div.innerBasic #ContBoxHeader #HeaderMenu ul li.tel span:nth-of-type(2):before {
                      content: "";
                      background: url("/common/img/tel_icon.png") no-repeat scroll left center;
                      background-size: 30px auto;
                      display: inline-block;
                      width: 30px;
                      height: 20px;
                      position: relative;
                      right: 7px; }
              #Header div.innerBasic #ContBoxHeader #HeaderMenu ul li.advice {
                position: absolute;
                top: 30px;
                right: 0;
                border-radius: 5px;
                border: 2px solid #e9633a;
                background-color: #fff;
                width: 123px;
                height: 48px;
                text-align: center;
                padding-top: 4px; }
                #Header div.innerBasic #ContBoxHeader #HeaderMenu ul li.advice a {
                  color: #e9633a;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 18px; }
        #Header div.innerBasic #ContBoxHeader #MenuBtn.dispsp {
          display: none; }
  #GlobalNav {
    z-index: 100;
    width: 100%;
    height: 59px;
    background: #fefbf3;
    min-width: 1000px; }
    #GlobalNav ul {
      /*以下1行削除しないように li display inline-blockカラム落ち対策*/
      font-size: 0; }
      #GlobalNav ul li {
        display: inline-block;
        text-align: center;
        height: 59px; }
        #GlobalNav ul li a {
          margin: 0 auto; }
        #GlobalNav ul li:nth-of-type(1) {
          width: 143px; }
          #GlobalNav ul li:nth-of-type(1) a {
            width: 70px; }
        #GlobalNav ul li:nth-of-type(2) {
          width: 185px; }
          #GlobalNav ul li:nth-of-type(2) a {
            width: 193px; }
        #GlobalNav ul li:nth-of-type(3) {
          width: 255px; }
          #GlobalNav ul li:nth-of-type(3) a {
            width: 186px; }
        #GlobalNav ul li:nth-of-type(4) {
          width: 100px; }
          #GlobalNav ul li:nth-of-type(4) a {
            width: 95px; }
        #GlobalNav ul li:nth-of-type(5) {
          width: 148px; }
          #GlobalNav ul li:nth-of-type(5) a {
            width: 93px; }
        #GlobalNav ul li:nth-of-type(6) {
          width: 140px; }
          #GlobalNav ul li:nth-of-type(6) a {
            width: 131px; }
        #GlobalNav ul li a {
          display: block;
          height: 43px;
          color: #000;
          font-size: 18px;
          line-height: 46px; }
  #Page #GlobalNav ul li:nth-of-type(1) a,
  #PageContribution #GlobalNav ul li:nth-of-type(2) a,
  #PageContributionlist #GlobalNav ul li:nth-of-type(3) a,
  #PageTopics #GlobalNav ul li:nth-of-type(4) a,
  #PageCompany #GlobalNav ul li:nth-of-type(5) a,
  #PageContact #GlobalNav ul li:nth-of-type(6) a {
    border-bottom: 3px solid #86c441; }
  #MainImg {
    width: 100%;
    background: #ffffff url(../img/lowerImage.jpg) no-repeat center center;
    background-size: cover;
    min-width: 1000px; }
    #MainImg div#MainImgInner {
      margin: 0 auto;
      width: 100%;
      height: 240px; }
      #MainImg div#MainImgInner h2 {
        color: #fff;
        font-size: 60px;
        font-weight: 700;
        line-height: 240px;
        text-align: center;
        text-shadow: 0px 0 11px rgba(0, 0, 0, 0.2), 0px 0 11px rgba(0, 0, 0, 0.2); }
  #Page.pageIndex #MainImg div#MainImgInner {
    height: 650px; }
  /*TOPページ用定義*/
  #Page.pageIndex div#MainImg {
    background: #ffffff url(../img/mainimg_index.jpg) no-repeat center bottom;
    background-size: cover; }
    #Page.pageIndex div#MainImg h2 {
      position: relative;
      width: 1000px;
      background: url("/common/img/contents/mainimg_icon01.png") no-repeat scroll right bottom;
      background-size: 219px auto;
      padding-top: 400px;
      font-size: 70px;
      font-weight: 700;
      line-height: 95px;
      color: #fff;
      text-align: left;
      text-shadow: 0px 0 15px rgba(58, 93, 11, 0.3), 0px 0 15px rgba(58, 93, 11, 0.3); }
      #Page.pageIndex div#MainImg h2 .mainimgSpan,
      #Page.pageIndex div#MainImg h2 .mainimgSpan2 {
        display: inline-block; }
      #Page.pageIndex div#MainImg h2 .mainimgSpan2 {
        position: absolute;
        bottom: 66px;
        right: 18px; }
        #Page.pageIndex div#MainImg h2 .mainimgSpan2 span {
          display: block;
          color: #f64c18;
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          text-align: center;
          text-shadow: none; }
          #Page.pageIndex div#MainImg h2 .mainimgSpan2 span:nth-of-type(2) {
            margin-top: 4px;
            font-size: 24px;
            line-height: 30px; }
            #Page.pageIndex div#MainImg h2 .mainimgSpan2 span:nth-of-type(2) span {
              font-size: 24px;
              line-height: 30px;
              letter-spacing: -1.5px; }
  #Container {
    width: 100%;
    background: #fefbf3;
    min-width: 1000px; }
    #Container:after {
      content: "";
      display: block;
      visibility: hidden;
      clear: both;
      height: 0; }
  /*PC #TopicPath  パンくずリスト*/
  #TopicPath {
    /*以下1行削除しないように li display inline-blockカラム落ち対策*/
    font-size: 0;
    display: block;
    margin: 0 auto 10px;
    width: 1000px;
    padding: 10px 8px 5px 0;
    text-align: left; }
    #TopicPath li {
      display: inline-block;
      margin-right: 5px;
      color: #9e9e9e;
      font-size: 14px;
      line-height: 25px; }
      #TopicPath li a {
        display: inline-block; }
        #TopicPath li a:active, #TopicPath li a:link, #TopicPath li a:visited {
          color: #000; }
        #TopicPath li a:hover {
          color: #000;
          text-decoration: underline; }
      #TopicPath li + li {
        position: relative;
        padding-left: 16px; }
        #TopicPath li + li:before {
          content: ">";
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 7px;
          height: 25px; }
  /* #Main */
  #Main {
    width: 700px;
    float: left;
    min-height: 100px; }
    #Main h3 {
      color: #714721;
      font-size: 50px;
      font-weight: 700;
      text-align: center; }
  /* .sub */
  aside.sub {
    width: 240px;
    float: right;
    margin-top: 83px;
    margin-bottom: 210px; }
    aside.sub h3 {
      height: 40px;
      color: #714721;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      text-align: left;
      padding-left: 0; }
      aside.sub h3:nth-of-type(2) {
        margin-top: 40px; }
    aside.sub li.parent {
      display: block !important; }
    aside.sub .current a,
    aside.sub .current-cat a {
      color: #9e9e9e !important; }
      aside.sub .current a:hover,
      aside.sub .current-cat a:hover {
        opacity: 1;
        text-decoration: none !important;
        cursor: text; }
    aside.sub ul {
      width: 100%;
      margin: 0 auto;
      border: 1px solid #f0e9cf;
      border-radius: 15px;
      background-color: #fff8e0;
      padding: 20px 30px; }
      aside.sub ul li {
        display: block;
        width: 100%;
        color: #010101;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        background: url("/common/img/contents/arrow01.png") no-repeat scroll left center;
        background-size: 8px auto;
        padding-left: 13px; }
        aside.sub ul li:last-child {
          margin-bottom: 0; }
        aside.sub ul li a {
          line-height: 45px;
          width: 100%;
          height: 45px;
          padding-left: 10px;
          color: #333; }
          aside.sub ul li a:hover {
            opacity: 1; }
    aside.sub > ul.monthly {
      width: 100%; }
      aside.sub > ul.monthly li {
        display: block;
        height: auto; }
      aside.sub > ul.monthly > li {
        background: url(../img/contents/sp_icon_close.png) no-repeat top 14px left;
        background-size: 10px 10px;
        cursor: pointer; }
        aside.sub > ul.monthly > li.minus {
          background: url(../img/contents/sp_icon_open.png) no-repeat top 14px left;
          background-size: 10px 10px; }
        aside.sub > ul.monthly > li > span {
          display: block;
          width: 100%;
          line-height: 40px;
          padding-left: 10px; }
        aside.sub > ul.monthly > li ul {
          display: none;
          border: none;
          padding: 0 12px; }
          aside.sub > ul.monthly > li ul li {
            background: none;
            padding-left: 0; }
            aside.sub > ul.monthly > li ul li a {
              font-size: 18px;
              color: #80ab50;
              line-height: 37px; }
              aside.sub > ul.monthly > li ul li a:hover {
                text-decoration: underline; }
  /* .pageMove */
  nav.pageMove {
    margin: 40px 0; }
    nav.pageMove ul {
      width: 475px;
      margin: 0 auto;
      position: relative;
      text-align: center;
      font-size: 0; }
      nav.pageMove ul li {
        width: 35px;
        height: 36px;
        display: inline-block;
        line-height: 36px;
        text-align: center !important;
        font-size: 18px !important;
        color: #c5c5c5 !important; }
        nav.pageMove ul li.current {
          display: inline-block; }
          nav.pageMove ul li.current + li {
            margin-left: 2px; }
        nav.pageMove ul li a {
          text-align: center;
          color: #80ab50; }
          nav.pageMove ul li a:hover {
            text-decoration: underline; }
        nav.pageMove ul li.prev, nav.pageMove ul li.next {
          width: 100px;
          height: 36px;
          line-height: 36px;
          font-size: 16px !important; }
          nav.pageMove ul li.prev a, nav.pageMove ul li.next a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #fff;
            background-color: #80ab50;
            border-radius: 20px; }
            nav.pageMove ul li.prev a:hover, nav.pageMove ul li.next a:hover {
              text-decoration: none; }
        nav.pageMove ul li.prev {
          position: absolute;
          top: 0;
          left: 0; }
          nav.pageMove ul li.prev a {
            padding-right: 15px; }
            nav.pageMove ul li.prev a:before {
              content: "";
              display: inline-block;
              width: 7px;
              height: 14px;
              margin-right: 13px;
              background: url(../img/icon_prev.png) no-repeat center left;
              background-size: 7px auto; }
        nav.pageMove ul li.next {
          position: absolute;
          top: 0;
          right: 0; }
          nav.pageMove ul li.next a {
            padding-left: 15px; }
            nav.pageMove ul li.next a:after {
              content: "";
              display: inline-block;
              width: 7px;
              height: 14px;
              margin-left: 13px;
              background: url(../img/icon_next.png) no-repeat center left;
              background-size: 7px auto; }
    nav.pageMove.entryPageMove {
      margin-top: 30px; }
      nav.pageMove.entryPageMove ul {
        width: 240px; }
        nav.pageMove.entryPageMove ul:last-of-type {
          display: block; }
        nav.pageMove.entryPageMove ul li.prev {
          left: -90px; }
        nav.pageMove.entryPageMove ul li.next {
          right: -88px; }
        nav.pageMove.entryPageMove ul li:not(.btn) {
          width: auto; }
          nav.pageMove.entryPageMove ul li:not(.btn) a {
            font-size: 16px;
            line-height: 40px; }
  /*PC #FooterContact  レイアウト*/
  #FooterContact {
    height: 251px;
    background: #fef0d9;
    min-width: 1000px; }
    #FooterContact .footerCopy {
      width: 100%;
      margin-bottom: 23px;
      padding-top: 61px;
      font-size: 26px;
      text-align: center;
      color: #714721;
      font-weight: 700;
      padding-left: 14px;
      letter-spacing: 0.05em; }
    #FooterContact .contactSet {
      overflow: hidden;
      width: 785px;
      height: 84px;
      margin: 0 auto; }
      #FooterContact .contactSet .tel {
        float: left;
        width: 401px;
        margin-right: 17px;
        color: #fff;
        text-align: left; }
        #FooterContact .contactSet .tel span:nth-of-type(1) {
          height: 57px;
          display: inline-block;
          padding-left: 52px;
          background: url("/common/img/tel_icon.png") no-repeat scroll left center;
          background-size: 42px auto;
          font-family: 'Open Sans', sans-serif;
          text-align: right; }
          #FooterContact .contactSet .tel span:nth-of-type(1) a {
            color: #000;
            font-size: 50px;
            font-weight: 600; }
        #FooterContact .contactSet .tel span:nth-of-type(2) {
          display: block;
          text-align: center;
          letter-spacing: 1px;
          color: #e9633a;
          font-size: 15px;
          font-weight: 700;
          border-radius: 5px;
          border: 1px solid #e9633a;
          background-color: #fff;
          width: 368px;
          height: 24px;
          line-height: 24px; }
      #FooterContact .contactSet .mail {
        float: left;
        width: 367px;
        padding-top: 6px; }
        #FooterContact .contactSet .mail a {
          display: block;
          width: 367px;
          height: 70px;
          padding-left: 123px;
          border-radius: 50px;
          background: #e9633a url("../img/mail_icon.png") no-repeat scroll 73px center;
          background-size: 35px auto;
          color: #fff;
          font-size: 26px;
          letter-spacing: 2px;
          text-decoration: none;
          line-height: 70px; }
  /*PC #Footer  フッタ内  レイアウト*/
  #Footer {
    min-width: 1000px;
    padding-top: 60px;
    padding-bottom: 56px;
    background-color: #483421; }
    #Footer #FooterInfo {
      float: left;
      width: 360px; }
      #Footer #FooterInfo dl dt {
        height: 32px;
        color: #e9d8c6;
        font-size: 22px;
        letter-spacing: 1px; }
        #Footer #FooterInfo dl dt a {
          color: #e9d8c6;
          margin-left: 12px; }
      #Footer #FooterInfo dl dd {
        color: #e9d7c5;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.7px; }
        #Footer #FooterInfo dl dd span {
          font-size: 12px;
          vertical-align: bottom; }
          #Footer #FooterInfo dl dd span a {
            color: #e9d7c5;
            vertical-align: bottom; }
      #Footer #FooterInfo dl dt a,
      #Footer #FooterInfo dl dd a {
        font-size: 12px;
        text-decoration: underline;
        vertical-align: middle; }
        #Footer #FooterInfo dl dt a:hover,
        #Footer #FooterInfo dl dd a:hover {
          text-decoration: none; }
    #Footer #FooterNav {
      float: right;
      width: 500px;
      color: #fff;
      text-align: right; }
      #Footer #FooterNav ul li {
        display: inline-block;
        margin-bottom: 18px;
        color: #fff;
        font-size: 16px;
        border-right: 1px solid #644e3a;
        text-align: center; }
        #Footer #FooterNav ul li:nth-of-type(1) {
          width: 85px; }
        #Footer #FooterNav ul li:nth-of-type(2) {
          width: 195px; }
        #Footer #FooterNav ul li:nth-of-type(3) {
          width: 169px; }
        #Footer #FooterNav ul li:nth-of-type(4) {
          width: 110px;
          letter-spacing: -1px; }
        #Footer #FooterNav ul li:nth-of-type(5) {
          width: 106px; }
        #Footer #FooterNav ul li:nth-of-type(6) {
          width: 119px;
          margin-right: 3px; }
        #Footer #FooterNav ul li:nth-of-type(3), #Footer #FooterNav ul li:nth-of-type(6) {
          text-align: right;
          border: none; }
        #Footer #FooterNav ul li a {
          color: #fff; }
          #Footer #FooterNav ul li a:hover {
            text-decoration: underline; }
    #Footer #Copyright {
      margin-top: 27px;
      font-family: "Open Sans", sans-serif;
      font-size: 10px;
      letter-spacing: .8px;
      color: #6f5f4f; }
  /*PC  #PageTop  */
  #PageTop {
    position: fixed;
    z-index: 100;
    bottom: 82px;
    right: 20px;
    text-indent: -9999px; }
    #PageTop a:link,
    #PageTop a:visited {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: url("../img/pageTop.png");
      background-size: 60px auto; }
    #PageTop a:hover {
      opacity: .8; } }

@media print, screen and (max-width: 767px) {
  #Main p,
  #Main ul li {
    color: #010101;
    font-size: 18px;
    line-height: 29px; }
    #Main p + p,
    #Main ul li + p {
      margin-top: 11px; }
  /* リンク設定 */
  a:link, a:visited {
    color: #010101; }
  a:active {
    color: #010101;
    text-decoration: none; }
  a:link {
    color: #010101;
    text-decoration: none; }
  /* 基本設定 */
  .disppc {
    display: none; }
  .innerBasic {
    width: 100%; }
  /*Header*/
  #Header {
    position: relative;
    background: #fefbf3;
    height: 60px; }
    #Header #ContBoxHeader {
      display: table-cell; }
      #Header #ContBoxHeader h1 {
        background: url("/common/img/logo.png") no-repeat scroll 12px center;
        background-size: 240px auto;
        width: 253px;
        height: 60px;
        padding-left: 11px;
        text-indent: -9999px; }
        #Header #ContBoxHeader h1 a {
          display: block;
          width: 100%;
          height: 100%; }
      #Header #ContBoxHeader #HeaderLogo {
        display: table-cell;
        width: 252px;
        height: 120px;
        vertical-align: middle; }
    #Header #MenuBtn {
      position: absolute;
      z-index: 600;
      top: 5px;
      right: 16px;
      width: 50px;
      height: 50px;
      padding-top: 15px;
      background: url(../img/header_menu.png) no-repeat center center;
      background-size: 50px auto;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      line-height: 50px; }
  /* MenuSp GlobalNavSp */
  #MenuSp {
    display: none;
    width: 100%;
    position: absolute;
    top: 60px;
    z-index: 200; }
    #MenuSp #HeaderMenu ul li {
      display: block;
      text-align: center;
      background: #ffffff; }
    #MenuSp nav#GlobalNavSp {
      background: #fff; }
      #MenuSp nav#GlobalNavSp ul li {
        display: block;
        width: 100%;
        height: 59px;
        line-height: 59px;
        border-bottom: 1px solid #e7e7e7;
        font-size: 18px;
        background: url(../img/GlobalNavSp_menu.png) no-repeat right 18px center;
        background-size: 8px auto; }
        #MenuSp nav#GlobalNavSp ul li:nth-of-type(1) {
          border-top: 1px solid #e7e7e7; }
        #MenuSp nav#GlobalNavSp ul li a {
          display: table;
          width: 100%;
          height: 100%;
          padding-left: 10px;
          color: #000; }
      #MenuSp nav#GlobalNavSp p.tel a {
        font-weight: 600; }
  /* .pageMove SP */
  nav.pageMove {
    margin: 24px 0; }
    nav.pageMove ul {
      width: 100%;
      margin: 0 auto;
      position: relative;
      text-align: center;
      font-size: 0; }
      nav.pageMove ul li {
        width: 30px;
        height: 30px;
        display: inline-block;
        line-height: 15px;
        text-align: center !important;
        font-size: 16px !important;
        color: #c5c5c5 !important; }
        nav.pageMove ul li.current {
          display: inline-block; }
          nav.pageMove ul li.current + li {
            margin-left: 2px; }
        nav.pageMove ul li a {
          text-align: center;
          color: #80ab50; }
        nav.pageMove ul li.prev, nav.pageMove ul li.next {
          width: 100px;
          height: 36px;
          line-height: 36px !important;
          background-color: #80ab50;
          border-radius: 20px;
          font-size: 14px !important; }
          nav.pageMove ul li.prev a, nav.pageMove ul li.next a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #fff; }
        nav.pageMove ul li.prev {
          position: absolute;
          top: 50px;
          left: 0; }
          nav.pageMove ul li.prev a {
            padding-right: 15px; }
            nav.pageMove ul li.prev a:before {
              content: "";
              display: inline-block;
              width: 7px;
              height: 14px;
              margin-right: 13px;
              background: url(../img/icon_prev.png) no-repeat center left;
              background-size: 7px auto;
              position: relative;
              top: 2px; }
        nav.pageMove ul li.next {
          position: absolute;
          top: 50px;
          right: 0; }
          nav.pageMove ul li.next a {
            padding-left: 15px; }
            nav.pageMove ul li.next a:after {
              content: "";
              display: inline-block;
              width: 7px;
              height: 14px;
              margin-left: 13px;
              background: url(../img/icon_next.png) no-repeat center left;
              background-size: 7px auto;
              position: relative;
              top: 2px; }
    nav.pageMove.entryPageMove {
      margin-top: 16px; }
      nav.pageMove.entryPageMove ul {
        width: 100%; }
        nav.pageMove.entryPageMove ul li:not(.btn) {
          width: auto; }
          nav.pageMove.entryPageMove ul li:not(.btn) a {
            font-size: 14px;
            line-height: 40px; }
  /*MenuspとFooterContact共通定義*/
  .contactArea {
    background: none !important;
    background-color: #fefbf3 !important;
    height: 230px !important;
    padding-top: 17px; }
    .contactArea .contactSet {
      width: 100%; }
      .contactArea .contactSet .tel {
        color: #fff;
        width: 315px;
        margin: 0 auto 21px; }
        .contactArea .contactSet .tel span {
          display: block; }
          .contactArea .contactSet .tel span:nth-of-type(1) {
            height: 57px;
            background: url("/common/img/tel_icon.png") no-repeat scroll left 24px;
            background-size: 33px auto;
            font-family: 'Open Sans', sans-serif;
            text-align: right;
            margin-bottom: 1px;
            letter-spacing: 1px;
            width: 300px;
            margin-right: auto;
            margin-left: auto; }
            .contactArea .contactSet .tel span:nth-of-type(1) a {
              color: #000;
              font-weight: 600;
              font-size: 39px;
              text-decoration: underline;
              padding-left: 0; }
          .contactArea .contactSet .tel span:nth-of-type(2) {
            text-align: center;
            letter-spacing: 1px;
            color: #e9633a;
            font-size: 13px;
            font-weight: 700;
            border-radius: 5px;
            border: 1px solid #e9633a;
            background-color: #fff;
            width: 100%;
            line-height: 24px; }
      .contactArea .contactSet .mail {
        width: 90%;
        margin: 0 auto; }
        .contactArea .contactSet .mail a {
          display: inline-block;
          background: #e9633a;
          width: 100%;
          height: 60px;
          border-radius: 50px;
          color: #fff !important;
          font-size: 24px;
          letter-spacing: 2px;
          text-decoration: none;
          line-height: 60px;
          text-align: center; }
          .contactArea .contactSet .mail a span:before {
            display: inline-block;
            content: "";
            background: #e9633a url("../img/mail_icon.png") no-repeat scroll left center;
            background-size: 35px auto;
            height: 21px;
            width: 35px;
            position: relative;
            top: 2px;
            right: 14px; }
  /*FooterContact個別定義*/
  #FooterContact.contactArea {
    background-color: #fef0d9 !important;
    height: 350px !important;
    padding-top: 50px; }
    #FooterContact.contactArea .footerCopy {
      color: #714721;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      padding-bottom: 20px; }
    #FooterContact.contactArea .contactSet .tel span:nth-of-type(1) {
      height: 48px;
      background: url(/common/img/tel_icon.png) no-repeat scroll left 13px !important;
      background-size: 33px auto !important; }
  /*MenuBack*/
  #MenuBack {
    display: none;
    overflow: hidden;
    position: absolute;
    z-index: 100;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 1; }
  /*mainMainImg SP*/
  #MainImg {
    width: 100%;
    height: 120px;
    background: #ffffff url(../img/lowerImage.jpg) no-repeat center center;
    background-size: cover; }
    #MainImg h2 {
      width: 100%;
      font-size: 35px;
      font-weight: 700;
      line-height: 120px;
      text-align: center;
      color: #fff;
      text-shadow: 0 0 10px #93a380, 0 0 10px #93a380; }
  /*TOPページ用定義*/
  #Page.pageIndex #MainImg {
    position: relative;
    width: 100%;
    height: 325px; }
    #Page.pageIndex #MainImg div#MainImgInner {
      background: #ffffff url(../img/mainimg_index.jpg) no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 325px; }
      #Page.pageIndex #MainImg div#MainImgInner h2 {
        position: absolute;
        bottom: 30px;
        color: #fff;
        font-size: 35px;
        font-weight: 700;
        text-align: left;
        line-height: 48px;
        padding-left: 15px;
        text-shadow: 0 0 10px #618b29, 0 0 10px #618b29; }
  /*Container*/
  #Container {
    background: #fefbf3; }
  /*スマホパン屑*/
  #TopicPath {
    margin: 10px 0;
    width: 100%;
    height: 25px;
    font-size: 12px;
    padding: 0 15px; }
    #TopicPath ol li {
      display: inline;
      color: #9e9e9e; }
      #TopicPath ol li a {
        line-height: 25px;
        color: #000; }
      #TopicPath ol li + li {
        padding-left: 13px;
        position: relative;
        margin-left: 0; }
      #TopicPath ol li + li:before {
        content: ">";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 2px;
        width: 7px;
        height: 10px;
        vertical-align: middle; }
      #TopicPath ol li:last-of-type:before {
        content: ">";
        display: inline-block;
        position: absolute;
        top: 1px;
        left: 1px;
        width: 7px;
        height: 10px;
        vertical-align: middle; }
  /*Main*/
  #Main h3 {
    color: #714721;
    font-size: 35px;
    font-weight: 700;
    text-align: center; }
  #Main section#ContBox01 {
    padding: 0 15px;
    min-height: 100px; }
  /* .sub */
  aside.sub {
    width: 90%;
    margin: 125px auto 50px; }
    aside.sub h3 {
      height: 36px;
      padding-left: 10px;
      color: #714721;
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
      text-align: left;
      padding-left: 0; }
      aside.sub h3:nth-of-type(2) {
        margin-top: 24px; }
    aside.sub ul {
      width: 100%;
      margin: 0 auto;
      border: 1px solid #f0e9cf;
      border-radius: 20px;
      background-color: #fff8e0;
      padding: 15px 30px; }
      aside.sub ul li {
        display: block;
        width: 100%;
        color: #010101;
        font-size: 16px;
        font-weight: 500;
        line-height: 33px;
        background: url("/common/img/contents/arrow01.png") no-repeat scroll left center;
        background-size: 8px auto;
        padding-left: 13px; }
        aside.sub ul li:last-child {
          margin-bottom: 0; }
        aside.sub ul li.current a:link,
        aside.sub ul li.current a:visited, aside.sub ul li.current-cat a:link,
        aside.sub ul li.current-cat a:visited {
          color: #9e9e9e !important; }
        aside.sub ul li a {
          line-height: 41px;
          width: 100%;
          padding-left: 10px;
          color: #333; }
    aside.sub > ul.monthly {
      width: 100%; }
      aside.sub > ul.monthly li {
        display: block;
        height: auto; }
      aside.sub > ul.monthly > li {
        background: url(../img/contents/sp_icon_close.png) no-repeat top 14px left;
        background-size: 10px 10px;
        cursor: pointer; }
        aside.sub > ul.monthly > li.minus {
          background: url(../img/contents/sp_icon_open.png) no-repeat top 14px left;
          background-size: 10px 10px; }
        aside.sub > ul.monthly > li > span {
          display: block;
          width: 100%;
          line-height: 40px;
          padding-left: 10px; }
        aside.sub > ul.monthly > li ul {
          display: none;
          border: none;
          padding: 0 12px; }
          aside.sub > ul.monthly > li ul li {
            background: none;
            padding-left: 0; }
            aside.sub > ul.monthly > li ul li a {
              font-size: 16px;
              color: #80ab50;
              line-height: 30px; }
  /*Footer*/
  #Footer {
    width: 100%;
    background: #483421; }
    #Footer #FooterMenu {
      display: none; }
    #Footer #FooterInfo {
      width: 100%;
      padding: 50px 15px 30px; }
      #Footer #FooterInfo dl dt {
        color: #e9d8c6;
        font-size: 20px;
        letter-spacing: 1px;
        margin-bottom: 20px; }
        #Footer #FooterInfo dl dt a {
          font-size: 10px;
          color: #e9d8c6;
          margin-left: 10px; }
      #Footer #FooterInfo dl dd {
        color: #e9d7c5;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.7px; }
        #Footer #FooterInfo dl dd span {
          font-size: 12px; }
          #Footer #FooterInfo dl dd span a {
            font-size: 10px;
            color: #e9d7c5; }
      #Footer #FooterInfo dl dt a,
      #Footer #FooterInfo dl dd a {
        position: relative;
        top: -1px;
        text-decoration: underline;
        vertical-align: middle; }
    #Footer #FooterNav nav {
      display: none; }
    #Footer #Copyright {
      padding: 13px 15px; }
      #Footer #Copyright small,
      #Footer #Copyright address {
        font-size: 9px;
        color: #6f5f4f;
        line-height: 14px; }
  /*sp  #PageTop  */
  #PageTop {
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 5px;
    text-indent: -9999px; }
    #PageTop a:link,
    #PageTop a:visited {
      display: block;
      width: 67px;
      height: 67px;
      border-radius: 50%;
      background: url("../img/pageTop.png") no-repeat center center;
      background-size: 80%; } }
